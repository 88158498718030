export const txTypesColors = {
  txTypeDecode: '#96AAFF80',
  txTypeMint: '#43FFC780',
  txTypeDefault: '#FFF',
  txTypeApprove: '#8BC34A80',
  txTypeCreateItemFromParts: '#BB55E580',
  // txTypeTreasury: '#FFFFFF',
  txTypeTreasury: '#BEADFF80',
  txTypeSale: '#3EFF8B80',
  txTypeActivate: '#FBFF3780',
  txTypeTransfer: '#FFD12F80',
};

export type TxTypeColorScheme = keyof typeof txTypesColors;

export const rarityColors = {
  Standard: '#FFFFFF',
  Refined: '#B9FBC5',
  Elite: '#FFD12F',
  Classified: '#64FFFF',
  Legendary: '#C9ACFF',
};

export const vlRarityColors = {
  Common: '#FFFFFF',
  Rare: '#B9FBC5',
  Epic: '#FFD12F',
  Legendary: '#64FFFF',
  Ancient: '#C9ACFF',
};

export type RarityColorScheme = keyof typeof rarityColors;
export type VLRarityColorScheme = keyof typeof vlRarityColors;

const colors = {
  green: {
    '50': '#BEFFD2',
    '100': '#C6F6D5',
    '200': '#3EFF8B',
    '400': '#48BB78',
    '500': '#38A169',
    '600': '#25855A',
    '900': '#1e3326',
  },
  blue: {
    '25': '#60FEFD',
    '50': '#EBF8FF',
    '100': '#BEE3F8',
    '200': '#90CDF4',
    '300': '#63B3ED',
    '400': '#4299E1',
    '500': '#3182CE',
    '600': '#2B6CB0',
    '700': '#2C5282',
    '800': '#2A4365',
    '900': '#1A365D',
  },
  red: {
    '500': '#E53E3E',
    '100': '#FED7D7',
  },
  orange: {
    '100': '#FEEBCB',
  },
  gray: {
    '25': '#ffffff33',
    '50': '#F7FAFC', // <-
    '75': '#989898',
    '100': '#EDF2F7',
    '150': '#636363',
    '200': '#E2E8F0',
    '250': '#7B7B95', // <<-
    '300': '#CBD5E0',
    '350': '#333333',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
  black: '#101112',
  white: '#ffffff',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
  purple: {
    '500': '#686783',
  },

  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#000000',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
  celo: '#FCFF52',

  ...txTypesColors,
  ...rarityColors,
  ...vlRarityColors,
};

/// Basic rarity and VL rarity have different colors presets

export function getRarityColor(color?: RarityColorScheme) {
  if (!color) {
    return rarityColors.Standard;
  }

  switch (color) {
    case 'Standard':
      return rarityColors.Standard;
    case 'Refined':
      return rarityColors.Refined;
    case 'Elite':
      return rarityColors.Elite;
    case 'Classified':
      return rarityColors.Classified;
    case 'Legendary':
      return rarityColors.Legendary;
    default:
      return rarityColors.Standard;
  }
}

export function getVLRarityColor(value?: number) {
  if (!value) {
    return vlRarityColors.Common;
  }

  switch (value) {
    case 0:
      return vlRarityColors.Common;
    case 1:
      return vlRarityColors.Rare;
    case 2:
      return vlRarityColors.Epic;
    case 3:
      return vlRarityColors.Legendary;
    case 4:
      return vlRarityColors.Ancient;
    default:
      return vlRarityColors.Common;
  }
}

export default colors;
