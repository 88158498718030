import { Image, Link, Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

export const FooterSocialMediaLinks = () => {
  const SocialLink = ({
    href,
    title,
    src,
    alt,
  }: {
    href: string;
    title: string;
    src: string;
    alt: string;
  }) => (
    <Link
      href={href}
      target="_blank"
      title={title}
      _hover={{ transform: 'scale(1.05)' }}
      ml={2}
    >
      <Image src={src} alt={alt} boxSize="25px" />
    </Link>
  );

  const sections = [
    {
      label: 'GUNZ Social media',
      links: [
        {
          href: 'https://discord.com/invite/gunbygunz',
          title: 'Discord',
          src: '/static/icons/social-media/discord.svg',
          alt: 'Discord link',
        },
        {
          href: 'https://x.com/GUNbyGUNZ',
          title: 'Twitter',
          src: '/static/icons/social-media/twitter.svg',
          alt: 'Twitter link',
        },
      ],
    },
    {
      label: 'OTG Social media',
      links: [
        {
          href: 'https://discord.com/invite/offthegrid',
          title: 'Discord',
          src: '/static/icons/social-media/discord.svg',
          alt: 'Discord link',
        },
        {
          href: 'https://twitter.com/playoffthegrid',
          title: 'Twitter',
          src: '/static/icons/social-media/twitter.svg',
          alt: 'Twitter link',
        },
        {
          href: 'https://www.facebook.com/PlayOffTheGrid',
          title: 'Facebook',
          src: '/static/icons/social-media/facebook.svg',
          alt: 'Facebook link',
        },
        {
          href: 'https://www.youtube.com/@playoffthegrid',
          title: 'YouTube',
          src: '/static/icons/social-media/youtube.svg',
          alt: 'YouTube link',
        },
        {
          href: 'https://www.tiktok.com/@playoffthegrid',
          title: 'TikTok',
          src: '/static/icons/social-media/tiktok.svg',
          alt: 'TikTok link',
        },
        {
          href: 'https://www.instagram.com/playoffthegrid',
          title: 'Instagram',
          src: '/static/icons/social-media/instagram.svg',
          alt: 'Instagram link',
        },
      ],
    },
  ];

  return (
    <Box w="fit-content" ml="auto" mt="8">
      {sections.map((section) => (
        <Flex
          key={section.label}
          flexWrap="wrap"
          align="center"
          justify="space-between"
          textTransform="uppercase"
          mt={section.label === 'OTG Social media' ? 4 : 0}
        >
          <Text opacity={0.4} pr={4}>
            {section.label}
          </Text>
          <Flex align="baseline" ml={section.label === 'GUNZ Social media' ? 'auto' : undefined}>
            {section.links.map((link) => (
              <SocialLink
                key={link.href}
                href={link.href}
                title={link.title}
                src={link.src}
                alt={link.alt}
              />
            ))}
          </Flex>
        </Flex>
      ))}
    </Box>
  );
};
