import { getEnvValue } from './utils';

const dataBaseUrl = getEnvValue('DATABASE_URL');

const isDev = getEnvValue('NEXT_PUBLIC_APP_ENV') === 'development';

const DataBase = Object.freeze({
    isDev,
    url: dataBaseUrl,
});

export default DataBase;
