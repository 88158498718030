import { Box, Link } from '@chakra-ui/react';
import React from 'react';

import app from 'configs/app/app';

type FooterItemType = {
    path: string;
    title: string;
};

const footerItems: FooterItemType[] = [
    { path: 'https://marketplace.gunz.dev/', title: 'Marketplace' },
    { path: app.baseUrl, title: 'Scanner' },
    { path: 'https://hacker.gunz.dev/', title: 'Hacker Platform' },
    { path: 'https://gunbygunz.com/', title: 'GUNZ Chain' },
    { path: 'https://gameoffthegrid.com/', title: 'Off The Grid' },
    { path: 'https://gunzillagames.com/en/', title: 'Gunzilla Games' },
];

const FooterLinks = () => {
    const renderMenuItem = (menuItem: FooterItemType, index: number) => (
        <Link
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            href={menuItem.path}
            _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s ease' }}
            textTransform="uppercase"
            fontWeight="bold"
        >
            {menuItem.title}
        </Link>
    );

    return (
        <Box display="flex" justifyContent="space-between" pt={8} flexWrap="wrap" gap={4} w="full">
            {footerItems.map((menuItem, index) => renderMenuItem(menuItem, index))}
        </Box>
    );
};

export default FooterLinks;
