import {
  Flex,
  Box,
  Image,
  Link,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React from 'react';

import config from 'configs/app';
import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';
import { CONTENT_MAX_WIDTH } from 'ui/shared/layout/utils';
import SearchBar from 'ui/snippets/searchBar/SearchBar';

const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M6 6l12 12M18 6l-12 12" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const HamburgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M3 6h18M3 12h18M3 18h18" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
const TopBar = () => {
  const handleExploreNetworkClick = React.useCallback(() => {
    window.open(config.UI.homepage?.exploreNetwork, '_blank');
  }, []);
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItems = [
    { href: '/txs', label: 'Transactions', icon: 'transactions' as IconName },
    { href: '/blocks', label: 'Blocks', icon: 'block' as IconName },
    { href: '/tokens', label: 'Tokens', icon: 'token' as IconName },
  ];

  return (
    <Box
      bgColor="rgba(0, 0, 0, 0.3)"
      backdropFilter="blur(25px)"
      top="0"
      width="100%"
      zIndex={2}
      borderBottom="1px solid"
      borderColor="divider"
    >
      <Flex
        py={2}
        px={{ base: 4, lg: 6 }}
        maxW={`${CONTENT_MAX_WIDTH + 255}px`}
        m="0 auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="row" alignItems="center">
          <Link href="/" mr={{ base: 4, md: 20 }}>
            <Image
              src="/static/gunz_logo_main_net.svg"
              alt="Gunz logo"
              boxSize={{ base: 10, md: 16 }}
              mr={2}
            />
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} direction="row" alignItems="center">
            {menuItems.map(({ href, label, icon }) => {
              const isActive = router.pathname === href;
              return (
                <Link
                  key={href}
                  href={href}
                  mr={6}
                  display="flex"
                  alignItems="center"
                  color={isActive ? 'white' : 'gray.400'}
                >
                  <IconSvg name={icon} boxSize="22px" mr={2} color={isActive ? 'white' : 'purple.500'} />
                  {label}
                </Link>
              );
            })}
          </Flex>
        </Flex>

        <Flex alignItems="center" width={{ base: '50%', md: '40%' }}>
          {config.UI.homepage?.exploreNetwork && (
              <Button
              display={{ base: 'none', md: 'block' }}
              onClick={handleExploreNetworkClick}
              variant="outline"
              colorScheme="gray"
              aria-label="Explore GUNZ Testnet"
              title="Explore GUNZ Testnet"
              fontWeight={ 500 }
              px={ 5 }
              mr={ 5 }
              h="32px"
              flexShrink={ 0 }
            >
              Explore GUNZ Testnet
            </Button>
          )}

          <SearchBar isHomepage width="100%" />
        </Flex>

        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="ghost"
          color="white"
          _hover={{ bg: 'transparent' }}
        />

        <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent bg="gray.800">
            <DrawerBody p={4}>
              <IconButton
                aria-label="Close menu"
                icon={<CloseIcon />}
                onClick={onClose}
                variant="ghost"
                color="white"
                _hover={{ bg: 'transparent' }}
                mb={4}
              />
              {menuItems.map(({ href, label, icon }) => {
                const isActive = router.pathname === href;
                return (
                  <Link
                    key={href}
                    href={href}
                    display="flex"
                    alignItems="center"
                    color={isActive ? 'white' : 'gray.400'}
                    fontSize="lg"
                    mb={3}
                    onClick={onClose}
                  >
                    <IconSvg name={icon} boxSize="24px" mr={3} color={isActive ? 'white' : 'purple.500'} />
                    {label}
                  </Link>
                );
              })}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default React.memo(TopBar);
